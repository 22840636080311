export const environment = {
  production: false,
  serverUrl: 'https://api-develop.fintekclub.com/api/v1',
  googleAnalyticsKey: '',
  currency: {
    list: '/currencies',
  },
  monthlyAverage: {
    list: '/monthly-averages',
  },
  returnDeadline: {
    list: '/return-deadlines',
  },
  entityType: {
    list: '/entity-types',
  },
  ubigeo: {
    listDepartment: '/ubigeo/departments',
    listProvince: '/ubigeo/provinces',
    listProvinceDepartment: '/ubigeo/provinces-department',
    listDistrictProvince: '/ubigeo/districts-province',
  },
  personalLoans: {
    list: '/personal-loan-products',
    get: '/personal-loan-products',
  },
  documentType: {
    list: '/document-types',
  },
  documentNumber: {
    get: '/document-number',
    verify: '/document-number/verify',
  },
  country: {
    list: '/countries',
  },
  roadType: {
    list: '/road-types',
  },
  availabilitySchedule: {
    list: '/availability-schedules',
  },
  auth: {
    requestCodePhone: '/auth/request-code-phone',
    verifyCodePhone: '/auth/verify-code-phone',
    register: '/auth/register',
    resendCodeRegister: '/auth/resend-code-register',
    confirmRegister: '/auth/confirm-register',
    login: '/auth/login',
    forgotPassword: '/auth/forgot-password',
    confirmForgotPassword: '/auth/confirm-forgot-password',
  },
  gender: {
    list: '/genders',
  },
  userPersonalLoanHistory: {
    list: '/user-personal-loan-histories',
    create: '/user-personal-loan-histories',
  },
  fixedTermDeposit: {
    list: '/fixed-term-deposit-products',
  },
  userFixedTermDepositHistory: {
    list: '/user-fixed-term-deposit-histories',
    create: '/user-fixed-term-deposit-histories',
  },
  user: {
    profile: '/users/profile',
  },
};
